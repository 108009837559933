import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Image1 from "../images/gta_roofing_1.jpg"
import Image2 from "../images/gta_roofing_2.jpg"
import Image3 from "../images/gta_roofing_3.jpg"
import Image4 from "../images/gta_roofing_4.jpg"
import Image5 from "../images/gta_roofing_5.jpg"
import Image6 from "../images/gta_roofing_6.jpg"
import Image7 from "../images/gta_roofing_7.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Ulises Roofing - Quality roofs in the GTA, all work guaranteed" />

    <main class="bg-gray-100 dark:bg-gray-800 pb-8 relative justify-between">

      <div class="relative lg:flex lg:items-center container mx-auto">
        <div class="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 class="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <span class="block">
              Peace of mind starts with a quality roof
            </span>
          </h2>
          <p class="text-md mt-4 text-gray-400">
            Ulises Roofing provides you with the highest quality roofing products and services alongside excellent customer service. We understand your home is your largest investment, and we know it's essential to protect it with an expert-installed, quality roof.
          </p>
          <div class="lg:mt-0 lg:flex-shrink-0">
            <div class="mt-12 inline-flex rounded-md shadow">
              <a href="#quote" class="py-4 px-8 text-xl bg-indigo-500 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                Book a quote
              </a>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-8 lg:pr-8 p-4 sm:p-8 lg:p-24">
          <img src={Image4} class="rounded-lg w-1/2" alt="a man from ulises roofing installing a roof in the GTA" />
          <div className="lg:mt-4">
            <img src={Image5} class="rounded-lg mb-8" alt="a roof deck covered in synthetic underlay half covered in newbrown shingles" />
          </div>
        </div>
      </div>



      <div class="relative dark:bg-gray-800 p-8 pt-0">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
          <div class="lg:col-start-2 md:pl-6">
            <h4 class="text-2xl leading-8 font-extrabold text-gray-900 dark:text-white tracking-tight sm:leading-9">
              Roofing services
            </h4>
            <ul class="mt-10">
              <li>
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                        <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                        <rect x="10" y="12" width="4" height="4"></rect>
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                      Shingle installations
                    </h5>
                    <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                      We install high-quality shingles to manufacturer specifications ensuring you can take full advantage of manufacturer warranties. All our work is backed by our 15 year warranty on labour.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-store" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="3" y1="21" x2="21" y2="21"></line>
                        <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
                        <line x1="5" y1="21" x2="5" y2="10.85"></line>
                        <line x1="19" y1="21" x2="19" y2="10.85"></line>
                        <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                      Flat roofs
                    </h5>
                    <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                     We specialize in flat roof installations in Toronto, Mississauga, and the surrounding area.
                    </p>
                  </div>
                </div>
              </li>
              <li class="mt-10">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-bank" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <line x1="3" y1="21" x2="21" y2="21"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                        <polyline points="5 6 12 3 19 6"></polyline>
                        <line x1="4" y1="10" x2="4" y2="21"></line>
                        <line x1="20" y1="10" x2="20" y2="21"></line>
                        <line x1="8" y1="14" x2="8" y2="17"></line>
                        <line x1="12" y1="14" x2="12" y2="17"></line>
                        <line x1="16" y1="14" x2="16" y2="17"></line>
                      </svg>
                    </div>
                  </div>
                  <div class="ml-4">
                    <h5 class="text-lg leading-6 text-gray-900 dark:text-white font-bold">
                      Roof repairs
                            </h5>
                    <p class="mt-2 text-base leading-6 text-gray-500 dark:text-gray-300">
                      Your roof protects your home from the elements outside. When it's damaged, you need a team you can trust to fix it fast.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="mt-10 relative lg:mt-0 lg:col-start-1">
            <img src={Image7} alt="a newly installed grey roof with new metal vents" class="relative mx-auto shadow-lg rounded w-auto" />
          </div>
        </div>
      </div>

      <div id="quote" class="w-full sm:w-3/4 mx-auto shadow-lg my-8 px-6 py-6 bg-white dark:bg-gray-700 sm:rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div class="xl:w-0 xl:flex-1">
          <h2 class="text-2xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-3xl sm:leading-9">
            Call or email today to book a quote
          </h2>
          <p class="mt-3 max-w-3xl text-lg leading-6 text-gray-500">
              Book your no obligation quote today for your GTA roof installations and repairs.
          </p>
        </div>
        <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <a href="mailto:info@ulisesroofing.com" class="shadow w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-bold text-lg rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
              Email info@ulisesroofing.com
            </a>
            <div className="text-center text-gray-400 font-medium my-3">OR</div>
            <a href="tel:6479610856" class="shadow w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:bg-indigo-400 transition duration-150 ease-in-out">
              Call or WhatsApp (647) 961-0856
            </a>
          </div>
        </div>
      </div>

      <div class="container mx-auto flex flex-wrap align-center justify-center overflow-hidden items-center">
        <img src={Image1} alt="a newly installed black roof in the greater toronto area with new metal vents" className="object-contain my-2 px-2 w-1/2 md:w-1/4" />
        <img src={Image2} alt="skylights on a newly installed roof near toronto" className="object-contain my-2 px-2 w-1/2 md:w-1/4" />
        <img src={Image3} alt="a house in the toronto area under construction with a newly installed roof" className="object-contain my-2 px-2 w-1/2 md:w-1/4" />
        <img src={Image6} alt="a newly installed tan roof in the GTA" className="object-contain my-2 px-2 w-1/2 md:w-1/4" />
      </div>

      
      <div className="my-20">
        <div className="uppercase font-bold py-4 px-4 text-gray-400 text-center">What Customers Are Saying</div>
        <div class="bg-white shadow-lg sm:rounded-lg max-w-md py-8 pt-4 px-8 mx-auto">
          <div class="flex items-center mt-2 mb-4">
            <svg class="mr-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
            <svg class="mx-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
            <svg class="mx-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
            <svg class="mx-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
            <svg class="mx-1 w-4 h-4 fill-current text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"/></svg>
          </div>
          <h2 class="text-gray-800 text-xl font-semibold">Incredible attention to detail</h2>
          <p class="mt-2 text-gray-600">Elmer did an amazing and thorough job on our roof and made sure everything was perfect. He left us feeling confident that we were not only getting the best quality roof, but that it was installed in a way that would really last. I couldn't be more impressed.</p>
          <div className="mt-4">Conner F</div>
          <div className="text-gray-400">Roof Replacement, May 2021</div>
        </div>
      </div>


    </main>

  </Layout>
)

export default IndexPage
